import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ridberg';
  calculated = false;

  data = [
    {title: 'QA Engineer / Tester', level: [[300, 1000], [1000, 2000], [2000, 4000]]},
    {title: 'Project Manager', level: [[300, 1500], [1500, 2000], [2000, 5000]]},
    {title: 'Business Analyst', level: [[300, 1000], [1000, 2000], [2000, 4000]]},
    {title: 'System Administrator', level: [[300, 1000], [1000, 2000], [2000, 3000]]},
    {title: 'HR', level: [[300, 1000], [1000, 2000], [2000, 3000]]},
    {title: 'Web Designer', level: [[300, 1000], [1000, 2000], [2000, 3000]]},
    {title: 'SEO Specialist', level: [[300, 1000], [1000, 2000], [2000, 3000]]},
    {title: 'Data Scientist', level: [[500, 1500], [1500, 3000], [3000, 6000]]},
    {title: 'QA Automation Engineer', level: [[500, 1500], [1500, 3000], [3000, 6000]]},
    {title: 'DevOps Engineer', level: [[500, 1500], [1500, 3000], [3000, 6000]]},
    {title: 'Product Manager', level: [[500, 1500], [1500, 3000], [3000, 6000]]},
    {title: 'Frontend developer', level: [[500, 1500], [1500, 2500], [2500, 5000], [5000, 6000], [6000, 7000]]},
    {title: 'Backend developer', level: [[500, 1500], [1500, 2500], [2500, 5000], [5000, 6000], [6000, 7000]]},
    {title: 'Fullstack developer', level: [[600, 1800], [1800, 2800], [2800, 5500], [5500, 6500], [6500, 7500]]},
    {title: 'Mobile developer', level: [[500, 1500], [1500, 2500], [2500, 5000], [5000, 6000], [6000, 7000]]},
  ];

  levels = ['junior', 'middle', 'senior', 'lead', 'architect'];

  office = true;

  output: any = [{title: undefined, count: 1}];
  result = [];

  ngOnInit(): void {
    console.log(this);
  }

  compare(o1: any, o2: any): boolean {
    return o1 && o2 && o1 === o2;
  }

  roundRate(position: number, sum: number): number {
    return Math.round(sum / position) * position;
  }

  total(): any {
    return {
      lowest: this.output.filter(Boolean).filter(i => i.title).reduce((acc, next) => {
        if (next.selectedLevel && next.count) {
          return acc + (this.margin(next.selectedLevel[0], next.count));
        } else if (next.selectedLevel && !next.count) {
          return acc + (this.margin(next.selectedLevel[0]));
        } else if (!next.selectedLevel && next.count) {
          return acc + (this.margin(this.search(next.title).lower, next.count));
        } else if (!next.selectedLevel && !next.count) {
          return acc + (this.margin(this.search(next.title).lower));
        }
      }, 0),
      highest: this.output.filter(Boolean).filter(i => i.title).reduce((acc, next) => {
        if (next.selectedLevel && next.count) {
          return acc + (this.margin(next.selectedLevel[1], next.count));
        } else if (next.selectedLevel && !next.count) {
          return acc + (this.margin(next.selectedLevel[1]));
        } else if (!next.selectedLevel && next.count) {
          return acc + (this.margin(this.search(next.title).highest, next.count));
        } else if (!next.selectedLevel && !next.count) {
          return acc + (this.margin(this.search(next.title).highest));
        }
      }, 0)
    };
  }

  top(): void {
    window.scrollTo(0, 0);
  }

  changeTitle(index): void {
    this.output[index].selectedLevel = undefined;
    this.output[index].count = 1;
  }

  changeQual(index): void {
    this.output[index].count = 1;
  }

  search(title: string): any {
    const item = this.data.find(i => i.title === title);

    return {
      lower: item.level[0][0],
      highest: item.level[item.level.length - 1][1]
    };
  }

  searchByTitle(title: string): any {
    return this.data.find(i => i.title === title);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  margin(sum, count = 1): number {
    if (this.office) {
      sum = (sum * 1.18 + 170) * count;
    } else {
      sum = (sum * 1.486) * count;
    }

    sum = sum + (sum / 21 * 25) / 12;

    sum = sum + (1750 * count);

    return sum;
  }

  format(n: number): string {
    return (n).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  add(): void {
    this.output = this.output.filter(Boolean);

    this.output.push({ title: undefined, count: 1 });
  }

  remove(index): void {
    this.output.splice(index, 1);
  }

  calculate(): void {
    this.calculated = true;
    console.log(this.output);
  }
}
