<style>
  .home-header-v-2-section-cost-btn img {
    position: relative;
    top: -4px;
  }

  .home-header-v-2-section-btn img {
    position: relative;
    top: -6px;
  }

  .header {
    background-color: #fff;
  }

  @media only screen and (max-width: 991px) {
    .home-header-v-2-section-btn a img {
      width: 35px !important
    }
  }
</style>

<div class="header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mobile-sectio-b">
        <img (click)="top()" class="logo" src="assets/ridbergPNG.png" />
      </div>
      <div class="col-xl-10 col-lg-10 col-md-6 col-sm-6 desktop-menu mobile-sectio-b">
        <div class="des-tpo-btn-menu"> <a href="https://www.linkedin.com/in/dmitriy-golubev-478598122/" role="button">Contact us</a></div>
      </div>
    </div>
  </div>
  <div class="mobile-menu-hide">
    <div class="flex-btn"> <a href="https://www.linkedin.com/in/dmitriy-golubev-478598122/" role="button">Contact us</a></div>
  </div>
</div>
<div class="main">

  <div class="home-header-v-2-section-bg mobile-hide-v-2-section-b">

    <div class="back">
      <img src="assets/home-v-2-header-bottom-bg.png" alt="bg-image" />

      <div class="home-header-v-2-section-bg-img-row">

      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="home-header-v-2-section">
            <div class="bg">
              <h1 class="h1-title"><span>EXPERT</span> SOFTWARE DEV TEAMS</h1>
              <p class="seed">for seed+ startups and SMBs in Israel, the US, Australia, and DACH</p>

              <div class="lets-padding">
                <div class="lets">
                  <div class="lets__title">Let's put together a team for your business!</div>
                  <div class="lets__subtitle">Belarus is Silicon Valley in Eastern Europe</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="calculator calculator-desktop shadow-sm">
        <h3 class="text-center title-team"><b><span class="team">TEAM</span> CALCULATION</b></h3>

        <p class="specify">Specify what kind of talents you need and see summary cost</p>

        <div class="col-12 mb-3" hidden>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" [(ngModel)]="office" id="officepark2">
            <label class="custom-control-label" for="officepark2">Do you need office?</label>
          </div>
        </div>

        <div class="row mb-2" *ngFor="let item of output; let outputIndex = index; trackBy:trackByIndex;">
          <div class="col-12 col-lg-4 mb-2">
            <div *ngIf="!outputIndex"><b>Specialization</b></div>
            <select class="form-control" [(ngModel)]="output[outputIndex].title" (ngModelChange)="changeTitle(outputIndex)" name="title--{{outputIndex}}">
              <option [ngValue]="undefined" disabled>Select..</option>
              <option *ngFor="let item of data" [ngValue]="item.title">{{item.title}}</option>
            </select>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <div *ngIf="!outputIndex"><b>Qualification</b></div>
            <select class="form-control" [disabled]="!output[outputIndex].title"  (ngModelChange)="changeQual(outputIndex)" [(ngModel)]="output[outputIndex].selectedLevel" name="level--{{outputIndex}}">
              <option [value]="undefined" disabled>Select..</option>
              <ng-container *ngIf="output[outputIndex].title">
                <option [value]="1" *ngFor="let item of searchByTitle(output[outputIndex].title).level; let levelIndex = index;" [ngValue]="item">{{levels[levelIndex]}}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <div *ngIf="!outputIndex"><b>Number</b></div>
            <div class="d-md-flex">
              <select class="form-control mb-4 mb-md-0" [disabled]="!output[outputIndex].title" [(ngModel)]="output[outputIndex].count" name="count--{{outputIndex}}">
                <option [ngValue]="undefined" disabled>Select..</option>
                <option [value]="1">1</option>
                <option [value]="2">2</option>
                <option [value]="3">3</option>
                <option [value]="4">4</option>
                <option [value]="5">5</option>
                <option [value]="6">6</option>
                <option [value]="7">7</option>
                <option [value]="8">8</option>
                <option [value]="9">9</option>
                <option [value]="10">10</option>
              </select>

              <button *ngIf="outputIndex > 0 || output.length > 1" class="btn btn-primary ml-md-3 w-100 w-lg-auto" (click)="remove(outputIndex)">
                <svg id="Capa_1" fill="#fff" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m337.642 196.835-18.656 221.13c-.834 9.9 14.104 11.252 14.947 1.261l18.656-221.13c.348-4.127-2.716-7.755-6.843-8.104-4.132-.348-7.757 2.715-8.104 6.843z"/><path d="m166.254 189.992c-4.127.349-7.19 3.977-6.843 8.104l18.656 221.13c.844 10.003 15.781 8.639 14.947-1.261l-18.656-221.13c-.347-4.128-3.978-7.189-8.104-6.843z"/><path d="m248.5 197.465v221.13c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-221.13c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5z"/><path d="m445.367 57.932h-117.938v-26.361c0-17.408-14.163-31.571-31.572-31.571h-79.715c-17.408 0-31.571 14.163-31.571 31.571v26.361h-117.939c-19.075 0-34.594 15.519-34.594 34.594s15.519 34.594 34.594 34.594h8.067l28.058 332.564c2.494 29.557 27.208 52.316 56.916 52.316h192.654c29.662 0 54.418-22.713 56.916-52.316l3.89-46.107c.348-4.127-2.716-7.755-6.843-8.104-4.138-.355-7.756 2.715-8.104 6.843l-3.89 46.107c-1.825 21.632-20.26 38.577-41.969 38.577h-192.654c-21.709 0-40.144-16.945-41.969-38.577l-27.952-331.303h22.206c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5c-16.429 0-35.802 0-45.326 0-10.804 0-19.594-8.79-19.594-19.594s8.79-19.594 19.594-19.594h378.735c10.805 0 19.595 8.79 19.595 19.594s-8.79 19.594-19.595 19.594c-11.649 0-273.137 0-298.326 0-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h275.207l-21.11 250.205c-.348 4.127 2.716 7.755 6.843 8.104.215.018.428.027.64.027 3.856 0 7.135-2.957 7.465-6.87l21.216-251.466h8.066c19.075 0 34.595-15.519 34.595-34.594s-15.521-34.594-34.596-34.594zm-132.938 0h-112.858v-26.361c0-9.137 7.434-16.571 16.571-16.571h79.715c9.138 0 16.571 7.434 16.571 16.571v26.361z"/></g></g></svg>
              </button>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button class="btn btn-primary" (click)="add()" [ngClass]="{'mr-3': !calculated}">Add a specialist</button>
          <button class="btn btn-success" *ngIf="!calculated" (click)="calculate()">Calculate total</button>
        </div>

        <ng-container *ngIf="calculated">
          <div class="row mb-3">
            <div class="col-12 col-lg-4"><b>Resource</b></div>
            <div class="col-12 col-lg-4"><b>Cost</b></div>
            <div class="col-12 col-lg-4"><b>Rate</b></div>
          </div>

          <ng-container *ngFor="let item of output;">
            <div class="row mb-3" *ngIf="item && item.title">
              <div class="col-12 col-lg-4">
                <div *ngIf="item.title">{{item.title}} <span *ngIf="item.count">x {{item.count}}</span></div>
              </div>
              <div class="col-12 col-lg-4">
                <div *ngIf="item.title && !item.selectedLevel && !item.count">{{format(roundRate(10, margin(search(item.title).lower)))}} - {{format(roundRate(10, margin(search(item.title).highest)))}}</div>
                <div *ngIf="item.title && !item.selectedLevel && item.count">{{format(roundRate(10, margin(search(item.title).lower, item.count)))}} - {{format(roundRate(10, margin(search(item.title).highest, item.count)))}}</div>
                <div *ngIf="item.selectedLevel && !item.count">{{format(roundRate(10, margin(item.selectedLevel[0])))}} - {{format(roundRate(10, margin(item.selectedLevel[1])))}}</div>
                <div *ngIf="item.selectedLevel && item.count">{{format(roundRate(10, margin(item.selectedLevel[0], item.count)))}} - {{format(roundRate(10, margin(item.selectedLevel[1], item.count)))}}</div>
              </div>
              <div class="col-12 col-lg-4">
                <div *ngIf="item.title && !item.selectedLevel && !item.count">{{format(roundRate(1, margin(search(item.title).lower) / 168))}} / h - {{format(roundRate(1, margin(search(item.title).highest) / 168))}} / h</div>
                <div *ngIf="item.title && !item.selectedLevel && item.count">{{format(roundRate(1, margin(search(item.title).lower) / 168))}} / h - {{format(roundRate(1, margin(search(item.title).highest) / 168))}} / h</div>
                <div *ngIf="item.selectedLevel && !item.count">{{format(roundRate(1, margin(item.selectedLevel[0]) / 168))}} / h - {{format(roundRate(1, margin(item.selectedLevel[1]) / 168))}} / h</div>
                <div *ngIf="item.selectedLevel && item.count">{{format(roundRate(1, margin(item.selectedLevel[0]) / 168))}} / h - {{format(roundRate(1, margin(item.selectedLevel[1]) / 168))}} / h</div>
              </div>
            </div>
          </ng-container>

          <div class="row mb-3">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-4">
              <b>Your team:</b> {{format(roundRate(10, total().lowest))}} - {{format(roundRate(10, total().highest))}} per month
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="container">
      <div>
        <div class="steps-title">
          <div class="steps-title__txt">how <span>the magic</span> happens?</div>
          <div class="steps-title__desc">Specify what kind of talents you need and see summary cost</div>
        </div>

        <div class="steps">
          <div class="step">
            <div class="step__number">1</div>
            <div class="step__desc">Tell us what kind of <br> remote team you want</div>
          </div>
          <div class="step">
            <div class="step__number">2</div>
            <div class="step__desc">We offer you quality talents
              suited for your needs</div>
          </div>
          <div class="step">
            <div class="step__number">3</div>
            <div class="step__desc">You interview, test, pick
              and creates your new team</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-show-v-2-section-b">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="home-header-v-2-section">
            <h1>EXPERT SOFTWARE<br> DEV TEAMS</h1>
            <p>for seed+ startups and SMBs in Israel, the US, Australia, and DACH</p>
            <!--          <div class="home-header-v-2-section-btn"> <a href="https://www.linkedin.com/in/dmitriy-golubev-478598122/"> <img-->
            <!--            src="https://www.pinclipart.com/picdir/big/100-1003028_svg-linkedin-icon-vector-clipart.png" width="20px" alt="icon">-->
            <!--            Contact </a></div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="calculator">
      <img
        src="assets/home-v-2-header-bottom-bg-mobile.png" alt="image">

      <h5 class="text-center team-calculation"><b>Team calculation</b></h5>

      <p>Specify what kind of talents you need and see summary cost</p>

      <div class="col-12 mb-3" hidden>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" [(ngModel)]="office" id="officepark">
          <label class="custom-control-label" for="officepark">Do you need office?</label>
        </div>
      </div>

      <div class="row card shadow-sm m-2 p-0 pt-3 pb-3" *ngFor="let item of output; let outputIndex = index; trackBy:trackByIndex;">
        <div class="col-12 col-lg-4 mb-2">
          <div *ngIf="!outputIndex"><b>Specialization</b></div>
          <select class="form-control" [(ngModel)]="output[outputIndex].title" (ngModelChange)="changeTitle(outputIndex)" name="title--{{outputIndex}}">
            <option [ngValue]="undefined" disabled>Select..</option>
            <option *ngFor="let item of data" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-4 mb-2">
          <div *ngIf="!outputIndex"><b>Qualification</b></div>
          <select class="form-control" [disabled]="!output[outputIndex].title" (ngModelChange)="changeQual(outputIndex)" [(ngModel)]="output[outputIndex].selectedLevel" name="level--{{outputIndex}}">
            <option [value]="undefined" disabled>Select..</option>
            <ng-container *ngIf="output[outputIndex].title">
              <option [value]="1" *ngFor="let item of searchByTitle(output[outputIndex].title).level; let levelIndex = index;" [ngValue]="item">{{levels[levelIndex]}}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-12 col-lg-4 mb-2">
          <div *ngIf="!outputIndex"><b>Number</b></div>
          <div>
            <select class="form-control mb-3" [disabled]="!output[outputIndex].title" [(ngModel)]="output[outputIndex].count" name="count--{{outputIndex}}">
              <option [ngValue]="undefined" disabled>Select..</option>
              <option [value]="1">1</option>
              <option [value]="2">2</option>
              <option [value]="3">3</option>
              <option [value]="4">4</option>
              <option [value]="5">5</option>
              <option [value]="6">6</option>
              <option [value]="7">7</option>
              <option [value]="8">8</option>
              <option [value]="9">9</option>
              <option [value]="10">10</option>
            </select>

            <button *ngIf="outputIndex > 0 || output.length > 1" class="btn btn-primary w-100 w-lg-auto" (click)="remove(outputIndex)">
              <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m337.642 196.835-18.656 221.13c-.834 9.9 14.104 11.252 14.947 1.261l18.656-221.13c.348-4.127-2.716-7.755-6.843-8.104-4.132-.348-7.757 2.715-8.104 6.843z"/><path d="m166.254 189.992c-4.127.349-7.19 3.977-6.843 8.104l18.656 221.13c.844 10.003 15.781 8.639 14.947-1.261l-18.656-221.13c-.347-4.128-3.978-7.189-8.104-6.843z"/><path d="m248.5 197.465v221.13c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-221.13c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5z"/><path d="m445.367 57.932h-117.938v-26.361c0-17.408-14.163-31.571-31.572-31.571h-79.715c-17.408 0-31.571 14.163-31.571 31.571v26.361h-117.939c-19.075 0-34.594 15.519-34.594 34.594s15.519 34.594 34.594 34.594h8.067l28.058 332.564c2.494 29.557 27.208 52.316 56.916 52.316h192.654c29.662 0 54.418-22.713 56.916-52.316l3.89-46.107c.348-4.127-2.716-7.755-6.843-8.104-4.138-.355-7.756 2.715-8.104 6.843l-3.89 46.107c-1.825 21.632-20.26 38.577-41.969 38.577h-192.654c-21.709 0-40.144-16.945-41.969-38.577l-27.952-331.303h22.206c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5c-16.429 0-35.802 0-45.326 0-10.804 0-19.594-8.79-19.594-19.594s8.79-19.594 19.594-19.594h378.735c10.805 0 19.595 8.79 19.595 19.594s-8.79 19.594-19.595 19.594c-11.649 0-273.137 0-298.326 0-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h275.207l-21.11 250.205c-.348 4.127 2.716 7.755 6.843 8.104.215.018.428.027.64.027 3.856 0 7.135-2.957 7.465-6.87l21.216-251.466h8.066c19.075 0 34.595-15.519 34.595-34.594s-15.521-34.594-34.596-34.594zm-132.938 0h-112.858v-26.361c0-9.137 7.434-16.571 16.571-16.571h79.715c9.138 0 16.571 7.434 16.571 16.571v26.361z"/></g></g></svg>
            </button>
          </div>
        </div>
      </div>

      <div class="pl-2 pr-2">
        <div class="text-right mt-3">
          <button class="btn btn-primary" (click)="add()" [ngClass]="{'mr-3': !calculated}">Add a specialist</button>
          <button *ngIf="!calculated" class="btn btn-success" (click)="calculate()">Calculate total</button>
        </div>
      </div>

      <ng-container *ngIf="calculated">
        <div class="col-12 mb-3" hidden>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" [(ngModel)]="office" id="officepark3">
            <label class="custom-control-label" for="officepark3">Do you need office?</label>
          </div>
        </div>

        <div class="mb-3">
          <div><b>Resource</b> / <b>Cost</b> / <b>Rate</b></div>
        </div>

        <ng-container *ngFor="let item of output; let outputIndex = index;">
          <div class="row mb-3" *ngIf="item && item.title">
            <div class="col-12 col-lg-4">
              <div *ngIf="item.title">{{item.title}} <span *ngIf="item.count">x {{item.count}}</span></div>
            </div>
            <div class="col-12 col-lg-4">
              <div *ngIf="item.title && !item.selectedLevel && !item.count">{{format(roundRate(10, margin(search(item.title).lower)))}} - {{format(roundRate(10, margin(search(item.title).highest)))}}</div>
              <div *ngIf="item.title && !item.selectedLevel && item.count">{{format(roundRate(10, margin(search(item.title).lower, item.count)))}} - {{format(roundRate(10, margin(margin(search(item.title).highest, item.count))))}}</div>
              <div *ngIf="item.selectedLevel && !item.count">{{format(roundRate(10, margin(item.selectedLevel[0])))}} - {{format(roundRate(10, margin(item.selectedLevel[1])))}}</div>
              <div *ngIf="item.selectedLevel && item.count">{{format(roundRate(10, margin(item.selectedLevel[0], item.count)))}} - {{format(roundRate(10, margin(item.selectedLevel[1], item.count)))}}</div>
            </div>
            <div class="col-12 col-lg-4">
              <div *ngIf="item.title && !item.selectedLevel && !item.count">{{format(roundRate(1, margin(search(item.title).lower) / 168))}} / h - {{format(roundRate(1, margin(search(item.title).highest) / 168))}} / h</div>
              <div *ngIf="item.title && !item.selectedLevel && item.count">{{format(roundRate(1, margin(search(item.title).lower) / 168))}} / h - {{format(roundRate(1, margin(search(item.title).highest) / 168))}} / h</div>
              <div *ngIf="item.selectedLevel && !item.count">{{format(roundRate(1, margin(item.selectedLevel[0]) / 168))}} / h - {{format(roundRate(1, margin(item.selectedLevel[1]) / 168))}} / h</div>
              <div *ngIf="item.selectedLevel && item.count">{{format(roundRate(1, margin(item.selectedLevel[0]) / 168))}} / h - {{format(roundRate(1, margin(item.selectedLevel[1]) / 168))}} / h</div>
            </div>
          </div>
        </ng-container>

        <div class="row mb-3">
          <div class="col-12 col-lg-4"></div>
          <div class="col-12 col-lg-4"></div>
          <div class="col-12 col-lg-4">
            <b>Your team:</b> {{format(total().lowest)}} - {{format(total().highest)}} per month
          </div>
        </div>
      </ng-container>
    </div>


    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="home-header-v-2-section-bottom-block">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h2>How the magic happens?</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div class="home-header-v-2-section-bottom-block-number">
                  <div class="number"><img
                    src="assets/home-v-2-numbers-icon-1.png" alt="icon">
                  </div>
                  <div class="text">Tell us what kind of <br> remote team you want</div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div class="home-header-v-2-section-bottom-block-number">
                  <div class="number"><img
                    src="assets/home-v-2-numbers-icon-2.png" alt="icon">
                  </div>
                  <div class="text">We offer you quality talents <br> suited for your needs</div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div class="home-header-v-2-section-bottom-block-number">
                  <div class="number"><img
                    src="assets/home-v-2-numbers-icon-3.png" alt="icon">
                  </div>
                  <div class="text">You interview, test, pick <br> and creates your new team</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container home-header-v-2-section-left-right" style="margin-top: 180px;">
    <div class="row">
      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
        <div class="row left-block">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobile-image-section-center"> <img
            src="assets/home-v-2-section-l-r-image-1.svg" alt="image">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <p> <span class="color2"><strong>We build & scale</strong></span> dedicated delivery teams for startups and SMBs in Israel, the US,
              Australia, and the DACH region</p>
          </div>
        </div>
        <div class="row right-block">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobile-v-2-img mobile-image-section-center"> <img
            src="assets/home-v-2-section-l-r-image-2.svg" alt="image">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <p> <span class="color2"><strong>We eliminate hassle</strong></span> of searching, interviewing, hiring, and taking care of the
              administration and payroll of employees</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 desktop-v-2-img"> <img
            src="assets/home-v-2-section-l-r-image-2.svg" alt="image">
          </div>
        </div>
        <div class="row left-block">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobile-image-section-center"> <img
            src="assets/home-v-2-section-l-r-image-3.svg" alt="image">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <p> <span class="color2"><strong>We take responsibility</strong></span> over the employees making sure your’e happy with their work</p>
          </div>
        </div>
        <div class="row right-block">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobile-v-2-img mobile-image-section-center"> <img
            src="assets/home-v-2-section-l-r-image-4.svg" alt="image">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <p> <span class="color2"><strong>We are always available</strong></span> for project wise guidance, customer service & more</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 desktop-v-2-img"> <img
            src="assets/home-v-2-section-l-r-image-4.svg" alt="image">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home-header-v-2-section-g">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
          <div class="home-header-v-2-section-g-block">
            <h3 class="top"><span>Top notch developers</span> <br> from Belarus huge market</h3>
            <p>114k of developers and rising, <br> we only offer the best</p>
            <img src="assets/devs.jpeg" class="devs" alt="image">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row home-header-v-2-section-cost">
      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobile-v-2-img">
            <div class="home-header-v-2-section-cost-img"> <img
              src="assets/home-v-2-img-cost.svg" alt="image"></div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="home-header-v-2-section-cost-b">
              <h3>How much does it cost?</h3>
              <p>Get a quick ballpark estimation for your dedicated team in under 1 minute</p>
              <div class="home-header-v-2-section-cost-btn" (click)="top()"> <a (click)="top()">CALCULATE</a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 desktop-v-2-img">
            <div class="home-header-v-2-section-cost-img"> <img
              src="assets/home-v-2-img-cost.svg" alt="image"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-bottom-section-bg">
    <div class="form-bottom-section-bg-box-1"></div>
    <div class="form-bottom-section-bg-box-2"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-center-block">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-bottom-block home-forms-bottom-page-v2">
                <div class="blog-forms-bottom-post home-forms-bottom-page">
                  <div class="get-in-touch-modal-content-text">
                    <h3>LET’S TALK</h3>
                    <p>Tell us about what you are trying to build</p>
                  </div>

                  <div class="home-header-v-2-section-btn">
                    <a href="https://www.linkedin.com/in/dmitriy-golubev-478598122/">

                      Contact us </a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  // document.addEventListener('resize', () => {
  //   [].slice.call(document.querySelectorAll('.row-card')).forEach((d) => {
  //     d.classList.toggle('card', window.innerWidth <= 991);
  //   });
  // });
</script>

<router-outlet></router-outlet>
